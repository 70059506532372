import actionTypes from "actions/types/vitemOrderActionTypes";
import GLOBAL from 'nonnglobal';
import customAxios from 'api/customAxios';

const API_URL = '/VItemOrderAction.do';
const VITEM_PURCHASE_API_URL = '/VItemPurchaseAction.do';
const GRADE_API_URL = '/GradeAction.do';


function listBuyVItemOrdersStarted(payload) {
    return { type: actionTypes.listBuyVItemOrdersStarted, payload };
}
function listBuyVItemOrdersSuccess(payload) {
    return { type: actionTypes.listBuyVItemOrdersSuccess, payload };
}
function listBuyVItemOrdersFailed(payload) {
    return { type: actionTypes.listBuyVItemOrdersFailed, payload };
}
function listBuyVItemOrders(payload = {}) {
    return dispatch => {

        dispatch(listBuyVItemOrdersStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {
            
            let form = new FormData();
            form.append('op', 'listBuyVItemOrders');
            form.append('start', payload.start);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listBuyVItemOrdersSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listBuyVItemOrdersFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listBuyVItemOrdersFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function getBuyVItemOrderInfoStarted(payload) {
    return { type: actionTypes.getBuyVItemOrderInfoStarted, payload };
}
function getBuyVItemOrderInfoSuccess(payload) {
    return { type: actionTypes.getBuyVItemOrderInfoSuccess, payload };
}
function getBuyVItemOrderInfoFailed(payload) {
    return { type: actionTypes.getBuyVItemOrderInfoFailed, payload };
}
function getBuyVItemOrderInfo(payload = {}) {
    return dispatch => {

        dispatch(getBuyVItemOrderInfoStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'getBuyVItemOrderInfo');
            form.append('orderid', payload.orderid);
            

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(getBuyVItemOrderInfoSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getBuyVItemOrderInfoFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getBuyVItemOrderInfoFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}


function listPurchaseOrdersStarted(payload) {
    return { type: actionTypes.listPurchaseOrdersStarted, payload };
}
function listPurchaseOrdersSuccess(payload) {
    return { type: actionTypes.listPurchaseOrdersSuccess, payload };
}
function listPurchaseOrdersFailed(payload) {
    return { type: actionTypes.listPurchaseOrdersFailed, payload };
}
function listPurchaseOrders(payload = {}) {
    return dispatch => {

        const {
            start,
            refresh = false,
        } = payload;

        dispatch(listPurchaseOrdersStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            let form = new FormData();
            form.append('op', 'listPurchaseOrders');
            form.append('start', start);

            customAxios(VITEM_PURCHASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listPurchaseOrdersSuccess({
                                result,
                                refresh,
                            }));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listPurchaseOrdersFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listPurchaseOrdersFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}


function getPurchaseOrderInfoStarted(payload) {
    return { type: actionTypes.getPurchaseOrderInfoStarted, payload };
}
function getPurchaseOrderInfoSuccess(payload) {
    return { type: actionTypes.getPurchaseOrderInfoSuccess, payload };
}
function getPurchaseOrderInfoFailed(payload) {
    return { type: actionTypes.getPurchaseOrderInfoFailed, payload };
}
function getPurchaseOrderInfo(payload = {}) {
    return dispatch => {

        dispatch(getPurchaseOrderInfoStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {


            let form = new FormData();
            form.append('op', 'getPurchaseOrderInfo');
            form.append('itemid', payload.itemid);
            // form.append('gameid', payload.gameid);
            // form.append('gameserverid', payload.gameserverid);
            // form.append('vprovmrscid', payload.viprovid);

            customAxios(VITEM_PURCHASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                dispatch(getPurchaseOrderInfoFailed());
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                dispatch(getPurchaseOrderInfoFailed(result.error));
                                return;
                            }

                            dispatch(getPurchaseOrderInfoSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getPurchaseOrderInfoFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getPurchaseOrderInfoFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function updPurchaseOrderStatusStarted(payload) {
    return { type: actionTypes.updPurchaseOrderStatusStarted, payload };
}
function updPurchaseOrderStatusSuccess(payload) {
    return { type: actionTypes.updPurchaseOrderStatusSuccess, payload };
}
function updPurchaseOrderStatusFailed(payload) {
    return { type: actionTypes.updPurchaseOrderStatusFailed, payload };
}
function updPurchaseOrderStatus(payload = {}) {
    return dispatch => {

        dispatch(updPurchaseOrderStatusStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'updPurchaseOrderStatus');
            form.append('itemid', payload.itemid);
            form.append('orderstatus', payload.orderstatus);
            form.append('gamename', payload.gamename);
            form.append('servername', payload.servername);
            form.append('grade', payload.grade);
            form.append('gradecontent', payload.gradecontent);

            customAxios(VITEM_PURCHASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(updPurchaseOrderStatusSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(updPurchaseOrderStatusFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(updPurchaseOrderStatusFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}


function getPurchaseOrderLogsStarted(payload) {
    return { type: actionTypes.getPurchaseOrderLogsStarted, payload };
}
function getPurchaseOrderLogsSuccess(payload) {
    return { type: actionTypes.getPurchaseOrderLogsSuccess, payload };
}
function getPurchaseOrderLogsFailed(payload) {
    return { type: actionTypes.getPurchaseOrderLogsFailed, payload };
}
function getPurchaseOrderLogs(payload = {}) {
    return dispatch => {

        dispatch(getPurchaseOrderLogsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {


            let form = new FormData();
            form.append('op', 'getPurchaseOrderLogs');
            form.append('itemid', payload.itemid);
            form.append('start', payload.start);

            customAxios(VITEM_PURCHASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(getPurchaseOrderLogsSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getPurchaseOrderLogsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getPurchaseOrderLogsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function addPurchaseOrderLogStarted(payload) {
    return { type: actionTypes.addPurchaseOrderLogStarted, payload };
}
function addPurchaseOrderLogSuccess(payload) {
    return { type: actionTypes.addPurchaseOrderLogSuccess, payload };
}
function addPurchaseOrderLogFailed(payload) {
    return { type: actionTypes.addPurchaseOrderLogFailed, payload };
}
function addPurchaseOrderLog(payload = {}) {
    return dispatch => {

        dispatch(addPurchaseOrderLogStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'addPurchaseOrderLog');
            form.append('itemid', payload.itemid);
            form.append('txtcontent', payload.txtcontent);
            form.append('passcode', payload.passcode);

            customAxios(VITEM_PURCHASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(addPurchaseOrderLogSuccess({result, ...payload}));
                            dispatch(getPurchaseOrderLogs({itemid: payload.itemid, start: 0}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(addPurchaseOrderLogFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(addPurchaseOrderLogFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function replyPurchaseOrderLogStarted(payload) {
    return { type: actionTypes.replyPurchaseOrderLogStarted, payload };
}
function replyPurchaseOrderLogSuccess(payload) {
    return { type: actionTypes.replyPurchaseOrderLogSuccess, payload };
}
function replyPurchaseOrderLogFailed(payload) {
    return { type: actionTypes.replyPurchaseOrderLogFailed, payload };
}
function replyPurchaseOrderLog(payload = {}) {
    return dispatch => {

        dispatch(replyPurchaseOrderLogStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'replyPurchaseOrderLog');
            form.append('itemid', payload.itemid);
            form.append('content', payload.content);
            form.append('msgid', payload.msgid);
            form.append('showflag', payload.showflag);


            customAxios(VITEM_PURCHASE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(replyPurchaseOrderLogSuccess({result, ...payload}));
                            dispatch(getPurchaseOrderLogs({itemid: payload.itemid, start: 0}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(replyPurchaseOrderLogFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(replyPurchaseOrderLogFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function addGradeStarted(payload) {
    return { type: actionTypes.addGradeStarted, payload };
}
function addGradeSuccess(payload) {
    return { type: actionTypes.addGradeSuccess, payload };
}
function addGradeFailed(payload) {
    return { type: actionTypes.addGradeFailed, payload };
}
function addGrade(payload = {}) {
    return dispatch => {

        dispatch(addGradeStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'addGrade');
            form.append('orderid', payload.orderid);
            form.append('viprovid', payload.viprovid);
            form.append('grade', payload.grade);
            form.append('gamename', payload.gamename);
            form.append('servername', payload.servername);
            form.append('amount', payload.amount);
            form.append('remark', payload.remark);


            customAxios(GRADE_API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(addGradeSuccess({result, ...payload}));
                            // 更新訂單資訊
                            dispatch(getBuyVItemOrderInfo({orderid: payload.orderid}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(addGradeFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(addGradeFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function addOrderLogStarted(payload) {
    return { type: actionTypes.addOrderLogStarted, payload };
}
function addOrderLogSuccess(payload) {
    return { type: actionTypes.addOrderLogSuccess, payload };
}
function addOrderLogFailed(payload) {
    return { type: actionTypes.addOrderLogFailed, payload };
}
function addOrderLog(payload = {}) {
    return dispatch => {

        dispatch(addOrderLogStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'addOrderLog');
            form.append('orderid', payload.orderid);
            form.append('txtcontent', payload.txtcontent);
            form.append('passcode', payload.passcode);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(addOrderLogSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(addOrderLogFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(addOrderLogFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function listOrderLogsStarted(payload) {
    return { type: actionTypes.listOrderLogsStarted, payload };
}
function listOrderLogsSuccess(payload) {
    return { type: actionTypes.listOrderLogsSuccess, payload };
}
function listOrderLogsFailed(payload) {
    return { type: actionTypes.listOrderLogsFailed, payload };
}
function listOrderLogs(payload = {}) {
    return dispatch => {

        dispatch(listOrderLogsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'listOrderLogs');
            form.append('orderid', payload.orderid);
            form.append('start', payload.start);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listOrderLogsSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listOrderLogsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listOrderLogsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}





const actions = {
    listBuyVItemOrders, // 列出購買訂單
    getBuyVItemOrderInfo, // 取得購買訂單資訊
    addGrade, // 會員對虛寶賣家訂單評價
    listPurchaseOrders, // 列出收購訂單
    getPurchaseOrderInfo, // 取得收購訂單資訊
    updPurchaseOrderStatus, // 更新收購訂單狀態
    getPurchaseOrderLogs, // 取得收購訂單留言
    addPurchaseOrderLog, // 新增收購訂單留言
    replyPurchaseOrderLog, // 回覆收購訂單留言

    addOrderLog, // 新增訂單留言
    listOrderLogs, // 訂單留言列表
    


};

export default actions;