import actionTypes from "actions/types/webActionTypes";

/*==========================
    Reducers
==========================*/
const reducers = {
	// 紀錄每個列表最後scroll到的位置(position)
	[actionTypes.storeScrollPosition]: function(state, action) {
		return storeScrollPosition(state, action);
	},
	// 紀錄每個列表最後scroll到的位置(index)
	[actionTypes.storeRenderedIndex]: function(state, action) {
		return storeRenderedIndex(state, action);
	},
	[actionTypes.showSignInDialog]: function (state, action){
		return showSignInDialog(state, action);
	},
	[actionTypes.hideSignInDialog]: function (state, action){
		return hideSignInDialog(state, action);
	},
	// 開啟提示視窗
	[actionTypes.openAlertDialog]: function(state, action) {
		return openAlertDialog(state, action);
	},
	// 關閉提示視窗
	[actionTypes.closeAlertDialog]: function(state, action) {
		return closeAlertDialog(state, action);
	},
	// 開啟確認Dialog
	[actionTypes.openConfirmDialog]: function(state, action) {
		return openConfirmDialog(state, action);
	},
	// 關閉確認Dialog
	[actionTypes.closeConfirmDialog]: function(state, action) {
		return closeConfirmDialog(state, action);
	},
	// 開啟 Simple Alert Dialog
	[actionTypes.showSimpleAlertDialog]: function(state, action) {
		return showSimpleAlertDialog(state, action);
	},
	// 開啟發票明細視窗
	[actionTypes.openInvoiceDetailDialog]: function(state, action) {
		return openInvoiceDetailDialog(state, action);
	},
	// 關閉發票明細視窗
	[actionTypes.closeInvoiceDetailDialog]: function(state, action) {
		return closeInvoiceDetailDialog(state, action);
	},
	[actionTypes.openForgotAccountDialog]: function(state, action) {
		return openForgotAccountDialog(state, action);
	},
	[actionTypes.closeForgotAccountDialog]: function(state, action) {
		return closeForgotAccountDialog(state, action);
	},
	[actionTypes.openForgetPwdDialog]: function(state, action) {
		return openForgetPwdDialog(state, action);
	},
	[actionTypes.closeForgetPwdDialog]: function(state, action) {
		return closeForgetPwdDialog(state, action);
	},
	[actionTypes.openAddProductDialog]: function(state, action) {
		return openAddProductDialog(state, action);
	},
	[actionTypes.closeAddProductDialog]: function(state, action) {
		return closeAddProductDialog(state, action);
	},
	[actionTypes.openSystemTextMsgDialog]: function(state, action) {
		return openSystemTextMsgDialog(state, action);
	},
	[actionTypes.closeSystemTextMsgDialog]: function(state, action) {
		return closeSystemTextMsgDialog(state, action);
	},
	// 成功取得web各種狀態 (金流開啟狀態與mobile是否維修)
	[actionTypes.checkWebStateSuccess]: function (state, action) {
		return checkWebStateSuccess(state, action);
	},
	[actionTypes.openBuyBonusPdtDialog]: (state, action) => openBuyBonusPdtDialog(state, action),
	[actionTypes.closeBuyBonusPdtDialog]: (state, action) => closeBuyBonusPdtDialog(state, action),
};

/*==========================
    Method
==========================*/

// ------------------------------
// 紀錄每個列表最後scroll到的位置(position)
// ------------------------------
function storeScrollPosition (state, action) {

	if (state.scrollPosition[action.payload.key] === action.payload.scrollTop) {
		return state;
	}

	const newScrollPosition = Object.assign({}, state.scrollPosition, {
		[action.payload.key]: action.payload.scrollTop
	});

	return Object.assign({}, state, {
		scrollPosition: newScrollPosition
	});
}

// ------------------------------
// 紀錄每個列表最後scroll到的位置(index)
// ------------------------------
function storeRenderedIndex (state, action) {
	
	if (state.renderedIndex[action.payload.key] === action.payload.event) {
		return state;
	}

	const newRenderedIndex = Object.assign({}, state.renderedIndex, {
		[action.payload.key]: action.payload.event
	});

	return Object.assign({}, state, {
		renderedIndex: newRenderedIndex
	});
}

function showSignInDialog (state){

	const { signInDialog } = state;
	const { isOpen } = signInDialog;

	if (isOpen){
		return state;
	}

	return Object.assign({}, state, {
		signInDialog: {
			isOpen: true,
		}
	});
}

function hideSignInDialog (state){

	const { signInDialog } = state;
	const { isOpen } = signInDialog;

	if (!isOpen){
		return state;
	}

	return Object.assign({}, state, {
		signInDialog: {
			isOpen: false,
		}
	});
}

// ------------------------------
// 開啟提示視窗
// ------------------------------
function openAlertDialog(state, action) {
	
	var alertDialog = state.alertDialog;

	if(alertDialog.isOpen) {
		return state;
	}

	const payload = action.payload;

	var new_alertDialog = Object.assign({}, alertDialog, {
		isOpen: true,
		title: payload.title || undefined,
		description: payload.description || undefined,
		renderDescription: payload.renderDescription || undefined,
		imageUrl: payload.imageUrl || undefined,
		closeName: payload.closeName || undefined,
		closeCallback: payload.closeCallback || undefined,
	});

    return Object.assign({}, state, {
        alertDialog: new_alertDialog,
    });
}

// ------------------------------
// 關閉提示視窗
// ------------------------------
function closeAlertDialog(state, action) {

	var alertDialog = state.alertDialog;

	if(!alertDialog.isOpen) {
		return state;
	}

    var new_alertDialog = Object.assign({}, alertDialog, {
		isOpen: false,
		title: undefined,
		description: undefined,
		closeName: undefined,
		closeCallback: undefined,
	});

    return Object.assign({}, state, {
        alertDialog: new_alertDialog,
    });
}

// ------------------------------
// 開啟確認Dialog
// ------------------------------
function openConfirmDialog(state, action) {
	// debugger
	var confirmDialog = state.confirmDialog;

	if(confirmDialog.isOpen) {
		return state;
	}

	const payload = action.payload;

	var new_confirmDialog = Object.assign({}, confirmDialog, {
		isOpen: true,
		title: payload.title || undefined,
		description: payload.description || undefined,
		isPromise: payload.isPromise,
		confirmCallback: payload.confirmCallback || undefined,
		confirmPromise: payload.confirmPromise || undefined,
		cancelCallback: payload.cancelCallback || undefined,
		closeCallback: payload.closeCallback || undefined,
		confirmName: payload.confirmName || undefined,
		cancelName: payload.cancelName || undefined,
	});

    return Object.assign({}, state, {
        confirmDialog: new_confirmDialog,
    });
}

// ------------------------------
// 關閉確認Dialog
// ------------------------------
function closeConfirmDialog(state, action) {

	var confirmDialog = state.confirmDialog;

	if(!confirmDialog.isOpen) {
		return state;
	}

    var new_confirmDialog = Object.assign({}, confirmDialog, {
		isOpen: false,
		title: undefined,
		description: undefined,
		isPromise: false,
		confirmCallback: undefined,
		confirmPromise: undefined,
		cancelCallback: undefined,
		confirmName: undefined,
		cancelName: undefined,
	});

    return Object.assign({}, state, {
        confirmDialog: new_confirmDialog,
    });
}

function showSimpleAlertDialog(state, { payload }) {
	return {
		...state,
		simpleAlertDialog: {
			timestamp: Date.now(),
			text: payload.text,
		},
	};
}

// ------------------------------
// 開啟提示視窗
// ------------------------------
function openInvoiceDetailDialog(state, action) {

	var invoiceDetailDialog = state.invoiceDetailDialog;
	if(invoiceDetailDialog.isOpen) {
		return state;
	}
	const payload = action.payload;
	var new_invoiceDetailDialog = Object.assign({}, invoiceDetailDialog, {
		isOpen: true,
		invoiceDetail : payload.invoiceDetail || undefined,
		closeName: payload.closeName || undefined,
		closeCallback: payload.closeCallback || undefined,
	});
	
    return Object.assign({}, state, {
        invoiceDetailDialog: new_invoiceDetailDialog,
    });
}

// ------------------------------
// 關閉提示視窗
// ------------------------------
function closeInvoiceDetailDialog(state) {

	var invoiceDetailDialog = state.invoiceDetailDialog;

	if(!invoiceDetailDialog.isOpen) {
		return state;
	}

    var new_invoiceDetailDialog = Object.assign({}, invoiceDetailDialog, {
		isOpen: false,
		invoiceDetail: undefined,
		closeName: undefined,
		closeCallback: undefined,
	});

    return Object.assign({}, state, {
        invoiceDetailDialog: new_invoiceDetailDialog,
    });
}

function openForgotAccountDialog(state) {

	const { forgotAccountDialog } = state;

	if(forgotAccountDialog.isOpen) return state;

	const new_state = Object.assign({}, state, {
		forgotAccountDialog: {
			isOpen: true,
		},
	});

	return new_state;
}

function closeForgotAccountDialog(state) {

	const { forgotAccountDialog } = state;

	if(!forgotAccountDialog.isOpen) return state;

	const new_state = Object.assign({}, state, {
		forgotAccountDialog: {
			isOpen: false,
		},
	});

	return new_state;
}

function openForgetPwdDialog(state) {

	const { forgetPwdDialog } = state;

	if(forgetPwdDialog.isOpen) return state;

	const new_state = Object.assign({}, state, {
		forgetPwdDialog: {
			isOpen: true,
		},
	});

	return new_state;
}

function closeForgetPwdDialog(state) {

	const { forgetPwdDialog } = state;

	if(!forgetPwdDialog.isOpen) return state;

	const new_state = Object.assign({}, state, {
		forgetPwdDialog: {
			isOpen: false,
		},
	});

	return new_state;
}

function openAddProductDialog(state, { payload }) {

	const { productid, routeToCart } = payload;
	const { addProductDialog } = state;

	if(addProductDialog.isOpen) return state;

	const new_state = Object.assign({}, state, {
		addProductDialog: {
			isOpen: true,
			productid,
			routeToCart,
		},
	});

	return  new_state;

}

function closeAddProductDialog(state) {

	const { addProductDialog } = state;

	if(!addProductDialog.isOpen) return state;

	const new_state = Object.assign({}, state, {
		addProductDialog: {
			isOpen: false,
		},
	});

	return  new_state;
}

function openSystemTextMsgDialog(state, { payload }) {
	
	const { unreadSystemTextMsgList = [] } = payload;
	const { systemTextMsgDialog } = state;

	if(systemTextMsgDialog.isOpen || (unreadSystemTextMsgList.length === 0))
		return state;

	const newState = Object.assign({}, state, {
		systemTextMsgDialog: {
			isOpen: true,
			unreadSystemTextMsgList,
		},
	});

	return newState;
}

function closeSystemTextMsgDialog(state) {
	
	const { systemTextMsgDialog } = state;

	if(!systemTextMsgDialog.isOpen) return state;

	const newState = Object.assign({}, state, {
		systemTextMsgDialog: {
			isOpen: false,
		},
	});

	return newState;
}

function checkWebStateSuccess(state, { payload }) {

	const { result = {} } = payload;
	const newState = Object.assign({}, state, result, {
		isCheckedWebState: true,
	});

	return newState;
}

function openBuyBonusPdtDialog(state, { payload }) {
	
	if (state.buyBonusPdtDialog.isOpen) {
		return state;
	}

	return {
		...state,
		buyBonusPdtDialog: {
			isOpen: true,
			productid: payload.productid,
			jsdproductid: payload.jsdproductid,
		},
	};
}

function closeBuyBonusPdtDialog(state) {
	
	if (!state.buyBonusPdtDialog.isOpen) {
		return state;
	}

	return {
		...state,
		buyBonusPdtDialog: {
			isOpen: false,
			productid: null,
			jsdproductid: null,
		},
	};
}

/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
	return function reducer(state = initialState, action) {
		if (reducers.hasOwnProperty(action.type)) {
			return reducers[action.type](state, action);
		} else {
			return state;
		}
	};
}