import url from 'url';
import CupoyRouter from 'components/commons/router/CupoyRouter';
/*--------------------------
    Variables
--------------------------*/
let domain = 'https://www.9199.com.tw/';
let isRelease = true;
var grecaptcha_key = '6LfJa3EUAAAAAEuCCizjmVAHzKAVP50Rn6yWCyrI';
var allPayCheckOutURL = 'https://payment-stage.ecpay.com.tw/Cashier/AioCheckOut/V2';
let imgSrcRootURL = '//img.inmy.design';

if (window.location.toString().toLowerCase().indexOf("localhost") !== -1) {
    domain = 'localhost.taocart.com:3000/';
    isRelease = false;
    grecaptcha_key = '6LfJa3EUAAAAAEuCCizjmVAHzKAVP50Rn6yWCyrI';
    allPayCheckOutURL = 'https://payment-stage.ecpay.com.tw/Cashier/AioCheckOut/V2';
} else if (window.location.toString().toLowerCase().indexOf("inmy.design") !== -1) {
    domain = 'https://www.inmy.design/';
    isRelease = false;
    grecaptcha_key = '6LfJa3EUAAAAAEuCCizjmVAHzKAVP50Rn6yWCyrI';
    allPayCheckOutURL = 'https://payment-stage.ecpay.com.tw/Cashier/AioCheckOut/V2';
} else if (window.location.toString().toLowerCase().indexOf("taocart.com") !== -1) {
    domain = 'https://beta.9199.taocart.com/';
    isRelease = false;
    grecaptcha_key = '6LfJa3EUAAAAAEuCCizjmVAHzKAVP50Rn6yWCyrI';
    allPayCheckOutURL = 'https://payment-stage.ecpay.com.tw/Cashier/AioCheckOut/V2';
    imgSrcRootURL = '//img.9199.taocart.com'
} else if (window.location.toString().toLowerCase().indexOf("9199.com") !== -1 ) {
    isRelease = true;
    grecaptcha_key = '6LfJa3EUAAAAAEuCCizjmVAHzKAVP50Rn6yWCyrI';
    allPayCheckOutURL = 'https://payment.ecpay.com.tw/Cashier/AioCheckOut/V2';
    imgSrcRootURL = '//img.9199.com.tw'
}

const fbconfig = {
    appId: '154931611903780',
    version: 'v3.1',
};
const ONE_DAY = 24 * 60 * 60 * 1000;
const PAYMENT_LIMIT_HOUR = 1;
const PAYMENT_LIMIT_HOUR_TEXT = PAYMENT_LIMIT_HOUR + "小時";

// 付款方式 (int)
const PAYTYPE = {
    ESUN: 1,
    MEGA: 2,
    ALLPAY_BANK: 3,
    ALLPAY_STORE: 4,
    ALLPAY_CREDITCARD: 5,
    FAMIPORT: 6,
    IBON: 7,
    HILIFE: 8,
    ECPAYATM: 9,
    CATHYATM: 10,
};

// 付款方式 (string)
const PAYTYPE_STRING = {
    [PAYTYPE.ESUN]: '玉山ATM付款',
    [PAYTYPE.MEGA]: '兆豐ATM付款',
    [PAYTYPE.ALLPAY_BANK]: '其他銀行ATM付款(歐付寶)',
    [PAYTYPE.ALLPAY_STORE]: '超商付款(歐付寶)',
    [PAYTYPE.ALLPAY_CREDITCARD]: '信用卡付款',
    [PAYTYPE.FAMIPORT]: '全家繳費',
    [PAYTYPE.IBON]: '7-11繳費',
    [PAYTYPE.HILIFE]: '萊爾富繳費',
    [PAYTYPE.ECPAYATM]: '綠界ATM',
    [PAYTYPE.CATHYATM]: '國泰世華ATM',
};

// 客訴處理狀態 (int)
const CASESTATUS = {
    NOTPROCESSED: 1,
    PROCESSED: 2,
    SUCCESS: 3,
    SUCCESS_REPLY: 4,
};

// 退款狀態 (string)
const CASESTATUS_STRING = {
    [CASESTATUS.NOTPROCESSED]: '未處理',
    [CASESTATUS.PROCESSED]: '處理中',
    [CASESTATUS.SUCCESS]: '已處理',
    [CASESTATUS.SUCCESS_REPLY]: '處理完畢且已回覆',
};

// 退款狀態 (int)
const REFUND_FLAG = {
    PROCESSED: 0,
    SUCCESS: 1,
    CANCEL: 2,
    FAIL: 3,
    REFUND_BY_ADMIN: 4,
};

// 退款狀態 (string)
const REFUND_FLAG_STRING = {
    [REFUND_FLAG.PROCESSED]: '處理中',
    [REFUND_FLAG.SUCCESS]: '已退款',
    [REFUND_FLAG.CANCEL]: '取消退款',
    [REFUND_FLAG.FAIL]: '退款失敗',
    [REFUND_FLAG.REFUND_BY_ADMIN]: '已主動退款',
    
};

// 發財金使用種類 (int)
const BONUS_OPTYPES = {
    AUTO_VITEM: 1,
    MANUALLY: 2,
    BUYBONUSCARD: 3,
    BUYBONUSCARD_REPAIR: 4,
    AUTO_C2CORDER: 5,
    AUTO_C2CPURCHASE: 6,
    AUTO_CARD: 7,
    AUTO_PROPS: 8,
    DEPOSIT: 9,
    AUTO_CARD_CARTORDER: 10,
};

// 發財金使用種類 (string)
const BONUS_OPTYPES_STRING = {
    [BONUS_OPTYPES.AUTO_VITEM]: '旗艦館-出售區(自動入點)',
    [BONUS_OPTYPES.MANUALLY]: '手動入點',
    [BONUS_OPTYPES.BUYBONUSCARD]: '交易扣點',
    [BONUS_OPTYPES.BUYBONUSCARD_REPAIR]: '交易失敗，人工沖帳',
    [BONUS_OPTYPES.AUTO_C2CORDER]: '拍賣會-出售區(自動入點)',
    [BONUS_OPTYPES.AUTO_C2CPURCHASE]: '拍賣會-收購區(自動入點)',
    [BONUS_OPTYPES.AUTO_CARD]: '購買點數卡(自動入點)',
    [BONUS_OPTYPES.AUTO_PROPS]: '旗艦館-道具卡出售區(自動入點)',
    [BONUS_OPTYPES.DEPOSIT]: '序號兌換(兌換入點)',
    [BONUS_OPTYPES.AUTO_CARD_CARTORDER]: '購買點數卡(自動入點)',
};

//台灣縣市列表
const CITIES = [
    {label:"基隆市",value:"基隆市",key: 0},
    {label:"台北市",value:"台北市",key: 1},
    {label:"新北市",value:"新北市",key: 2},
    {label:"桃園市",value:"桃園市",key: 3},
    {label:"新竹市",value:"新竹市",key: 4},
    {label:"新竹縣",value:"新竹縣",key: 5},
    {label:"苗栗縣",value:"苗栗縣",key: 6},
    {label:"台中市",value:"台中市",key: 7},
    {label:"彰化縣",value:"彰化縣",key: 8},
    {label:"南投縣",value:"南投縣",key: 9},
    {label:"雲林縣",value:"雲林縣",key:10},
    {label:"嘉義市",value:"嘉義市",key:11},
    {label:"嘉義縣",value:"嘉義縣",key:12},
    {label:"台南市",value:"台南市",key:13},
    {label:"高雄市",value:"高雄市",key:14},
    {label:"屏東縣",value:"屏東縣",key:15},
    {label:"台東縣",value:"台東縣",key:16},
    {label:"花蓮縣",value:"花蓮縣",key:17},
    {label:"宜蘭縣",value:"宜蘭縣",key:18},
    {label:"澎湖縣",value:"澎湖縣",key:19},
    {label:"金門縣",value:"金門縣",key:20},
    {label:"連江縣",value:"連江縣",key:21}
]
//台灣鄉鎮區列表  
const REGIONS = {
    "基隆市":[
        {zipcode:200,label:"仁愛區",value:"仁愛區"},
        {zipcode:201,label:"信義區",value:"信義區"},
        {zipcode:202,label:"中正區",value:"中正區"},
        {zipcode:203,label:"中山區",value:"中山區"},
        {zipcode:204,label:"安樂區",value:"安樂區"},
        {zipcode:205,label:"暖暖區",value:"暖暖區"},
        {zipcode:206,label:"七堵區",value:"七堵區"},
                
    ],
    
    "台北市":[
        {zipcode:100,label:"中正區",value:"中正區"},	
        {zipcode:103,label:"大同區",value:"大同區"}, 
        {zipcode:104,label:"中山區",value:"中山區"}, 
        {zipcode:105,label:"松山區",value:"松山區"}, 
        {zipcode:106,label:"大安區",value:"大安區"}, 
        {zipcode:108,label:"萬華區",value:"萬華區"}, 
        {zipcode:110,label:"信義區",value:"信義區"}, 
        {zipcode:111,label:"士林區",value:"士林區"}, 
        {zipcode:112,label:"北投區",value:"北投區"}, 
        {zipcode:114,label:"內湖區",value:"內湖區"}, 
        {zipcode:115,label:"南港區",value:"南港區"}, 
        {zipcode:116,label:"文山區",value:"文山區"} 
    ],
    "新北市":[
        {zipcode:207,label:"萬里區",value:"萬里區"},              
        {zipcode:208,label:"金山區",value:"金山區"},              
        {zipcode:220,label:"板橋區",value:"板橋區"},              
        {zipcode:221,label:"汐止區",value:"汐止區"},              
        {zipcode:222,label:"深坑區",value:"深坑區"},              
        {zipcode:223,label:"石碇區",value:"石碇區"},              
        {zipcode:224,label:"瑞芳區",value:"瑞芳區"},              
        {zipcode:226,label:"平溪區",value:"平溪區"},              
        {zipcode:227,label:"雙溪區",value:"雙溪區"},              
        {zipcode:228,label:"貢寮區",value:"貢寮區"},              
        {zipcode:231,label:"新店區",value:"新店區"},              
        {zipcode:232,label:"坪林區",value:"坪林區"},              
        {zipcode:233,label:"烏來區",value:"烏來區"},              
        {zipcode:234,label:"永和區",value:"永和區"},              
        {zipcode:235,label:"中和區",value:"中和區"},              
        {zipcode:236,label:"土城區",value:"土城區"},              
        {zipcode:237,label:"三峽區",value:"三峽區"},              
        {zipcode:238,label:"樹林區",value:"樹林區"},              
        {zipcode:239,label:"鶯歌區",value:"鶯歌區"},              
        {zipcode:241,label:"三重區",value:"三重區"},              
        {zipcode:242,label:"新莊區",value:"新莊區"},              
        {zipcode:243,label:"泰山區",value:"泰山區"},              
        {zipcode:244,label:"林口區",value:"林口區"},              
        {zipcode:247,label:"蘆洲區",value:"蘆洲區"},              
        {zipcode:248,label:"五股區",value:"五股區"},              
        {zipcode:249,label:"八里區",value:"八里區"},              
        {zipcode:251,label:"淡水區",value:"淡水區"},              
        {zipcode:252,label:"三芝區",value:"三芝區"},              
        {zipcode:253,label:"石門區",value:"石門區"}],
    "桃園市":[
        {zipcode:320,label:"中壢區",value:"中壢區"},        
        {zipcode:324,label:"平鎮區",value:"平鎮區"},
        {zipcode:325,label:"龍潭區",value:"龍潭區"},
        {zipcode:326,label:"楊梅區",value:"楊梅區"},
        {zipcode:327,label:"新屋區",value:"新屋區"},
        {zipcode:328,label:"觀音區",value:"觀音區"},
        {zipcode:330,label:"桃園區",value:"桃園區"},
        {zipcode:333,label:"龜山區",value:"龜山區"},
        {zipcode:334,label:"八德區",value:"八德區"},
        {zipcode:335,label:"大溪區",value:"大溪區"},
        {zipcode:336,label:"復興區",value:"復興區"},
        {zipcode:337,label:"大園區",value:"大園區"},
        {zipcode:338,label:"蘆竹區",value:"蘆竹區"}],
    "新竹市":[
        {zipcode:300,label:"東區" ,value:"東區"},
        {zipcode:300,label:"北區" ,value:"北區"},
        {zipcode:300,label:"香山區",value:"香山區"}],
    "新竹縣":[
        {zipcode:302,label:"竹北市",value:"竹北市"},
        {zipcode:303,label:"湖口鄉",value:"湖口鄉"},
        {zipcode:304,label:"新豐鄉",value:"新豐鄉"},
        {zipcode:305,label:"新埔鎮",value:"新埔鎮"},
        {zipcode:306,label:"關西鎮",value:"關西鎮"},
        {zipcode:307,label:"芎林鄉",value:"芎林鄉"},
        {zipcode:308,label:"寶山鄉",value:"寶山鄉"},
        {zipcode:310,label:"竹東鎮",value:"竹東鎮"},
        {zipcode:311,label:"五峰鄉",value:"五峰鄉"},
        {zipcode:312,label:"橫山鄉",value:"橫山鄉"},
        {zipcode:313,label:"尖石鄉",value:"尖石鄉"},
        {zipcode:314,label:"北埔鄉",value:"北埔鄉"},
        {zipcode:315,label:"峨眉鄉",value:"峨眉鄉"},
    ],    
    "苗栗縣":[
        {zipcode:350,label:"竹南鎮",value:"竹南鎮"},
        {zipcode:351,label:"頭份市",value:"頭份市"},
        {zipcode:352,label:"三灣鄉",value:"三灣鄉"},
        {zipcode:353,label:"南庄鄉",value:"南庄鄉"},
        {zipcode:354,label:"獅潭鄉",value:"獅潭鄉"},
        {zipcode:356,label:"後龍鎮",value:"後龍鎮"},
        {zipcode:357,label:"通霄鎮",value:"通霄鎮"},
        {zipcode:358,label:"苑裡鎮",value:"苑裡鎮"},
        {zipcode:360,label:"苗栗市",value:"苗栗市"},
        {zipcode:361,label:"造橋鄉",value:"造橋鄉"},
        {zipcode:362,label:"頭屋鄉",value:"頭屋鄉"},
        {zipcode:363,label:"公館鄉",value:"公館鄉"},
        {zipcode:364,label:"大湖鄉",value:"大湖鄉"},
        {zipcode:365,label:"泰安鄉",value:"泰安鄉"},
        {zipcode:366,label:"銅鑼鄉",value:"銅鑼鄉"},
        {zipcode:367,label:"三義鄉",value:"三義鄉"},
        {zipcode:368,label:"西湖鄉",value:"西湖鄉"},
        {zipcode:369,label:"卓蘭鎮",value:"卓蘭鎮"},
    ],
    "台中市":[
        {zipcode:400,label:"中區" ,value: "中區" },
        {zipcode:401,label:"東區" ,value: "東區" },
        {zipcode:402,label:"南區" ,value: "南區" },
        {zipcode:403,label:"西區" ,value: "西區" },
        {zipcode:404,label:"北區" ,value: "北區" },
        {zipcode:406,label:"北屯區",value: "北屯區"},
        {zipcode:407,label:"西屯區",value: "西屯區"},
        {zipcode:408,label:"南屯區",value: "南屯區"},
        {zipcode:411,label:"太平區",value: "太平區"},
        {zipcode:412,label:"大里區",value: "大里區"},
        {zipcode:413,label:"霧峰區",value: "霧峰區"},
        {zipcode:414,label:"烏日區",value: "烏日區"},
        {zipcode:420,label:"豐原區",value: "豐原區"},
        {zipcode:421,label:"后里區",value: "后里區"},
        {zipcode:422,label:"石岡區",value: "石岡區"},
        {zipcode:423,label:"東勢區",value: "東勢區"},
        {zipcode:424,label:"和平區",value: "和平區"},
        {zipcode:426,label:"新社區",value: "新社區"},
        {zipcode:427,label:"潭子區",value: "潭子區"},
        {zipcode:428,label:"大雅區",value: "大雅區"},
        {zipcode:429,label:"神岡區",value: "神岡區"},
        {zipcode:432,label:"大肚區",value: "大肚區"},
        {zipcode:433,label:"沙鹿區",value: "沙鹿區"},
        {zipcode:434,label:"龍井區",value: "龍井區"},
        {zipcode:435,label:"梧棲區",value: "梧棲區"},
        {zipcode:436,label:"清水區",value: "清水區"},
        {zipcode:437,label:"大甲區",value: "大甲區"},
        {zipcode:438,label:"外埔區",value: "外埔區"},
        {zipcode:439,label:"大安區",value: "大安區"},

    ],
    "彰化縣":[
        {zipcode:500,label:"彰化市",value:"彰化市"},
        {zipcode:502,label:"芬園鄉",value:"芬園鄉"},
        {zipcode:503,label:"花壇鄉",value:"花壇鄉"},
        {zipcode:504,label:"秀水鄉",value:"秀水鄉"},
        {zipcode:505,label:"鹿港鎮",value:"鹿港鎮"},
        {zipcode:506,label:"福興鄉",value:"福興鄉"},
        {zipcode:507,label:"線西鄉",value:"線西鄉"},
        {zipcode:508,label:"和美鎮",value:"和美鎮"},
        {zipcode:509,label:"伸港鄉",value:"伸港鄉"},
        {zipcode:510,label:"員林市",value:"員林市"},
        {zipcode:511,label:"社頭鄉",value:"社頭鄉"},
        {zipcode:512,label:"永靖鄉",value:"永靖鄉"},
        {zipcode:513,label:"埔心鄉",value:"埔心鄉"},
        {zipcode:514,label:"溪湖鎮",value:"溪湖鎮"},
        {zipcode:515,label:"大村鄉",value:"大村鄉"},
        {zipcode:516,label:"埔鹽鄉",value:"埔鹽鄉"},
        {zipcode:520,label:"田中鎮",value:"田中鎮"},
        {zipcode:521,label:"北斗鎮",value:"北斗鎮"},
        {zipcode:522,label:"田尾鄉",value:"田尾鄉"},
        {zipcode:523,label:"埤頭鄉",value:"埤頭鄉"},
        {zipcode:524,label:"溪州鄉",value:"溪州鄉"},
        {zipcode:525,label:"竹塘鄉",value:"竹塘鄉"},
        {zipcode:526,label:"二林鎮",value:"二林鎮"},
        {zipcode:527,label:"大城鄉",value:"大城鄉"},
        {zipcode:528,label:"芳苑鄉",value:"芳苑鄉"},
        {zipcode:530,label:"二水鄉",value:"二水鄉"},
    ],
    "南投縣":[
        {zipcode:540,label:"南投市",value:"南投市"},
        {zipcode:541,label:"中寮鄉",value:"中寮鄉"},
        {zipcode:542,label:"草屯鎮",value:"草屯鎮"},
        {zipcode:544,label:"國姓鄉",value:"國姓鄉"},
        {zipcode:545,label:"埔里鎮",value:"埔里鎮"},
        {zipcode:546,label:"仁愛鄉",value:"仁愛鄉"},
        {zipcode:551,label:"名間鄉",value:"名間鄉"},
        {zipcode:552,label:"集集鎮",value:"集集鎮"},
        {zipcode:553,label:"水里鄉",value:"水里鄉"},
        {zipcode:555,label:"魚池鄉",value:"魚池鄉"},
        {zipcode:556,label:"信義鄉",value:"信義鄉"},
        {zipcode:557,label:"竹山鎮",value:"竹山鎮"},
        {zipcode:558,label:"鹿谷鄉",value:"鹿谷鄉"},

    ],

    "雲林縣":[
        {zipcode:630,label:"斗南鎮",value:"斗南鎮"},
        {zipcode:631,label:"大埤鄉",value:"大埤鄉"},
        {zipcode:632,label:"虎尾鎮",value:"虎尾鎮"},
        {zipcode:633,label:"土庫鎮",value:"土庫鎮"},
        {zipcode:634,label:"褒忠鄉",value:"褒忠鄉"},
        {zipcode:635,label:"東勢鄉",value:"東勢鄉"},
        {zipcode:636,label:"台西鄉",value:"台西鄉"},
        {zipcode:637,label:"崙背鄉",value:"崙背鄉"},
        {zipcode:638,label:"麥寮鄉",value:"麥寮鄉"},
        {zipcode:640,label:"斗六市",value:"斗六市"},
        {zipcode:643,label:"林內鄉",value:"林內鄉"},
        {zipcode:646,label:"古坑鄉",value:"古坑鄉"},
        {zipcode:647,label:"莿桐鄉",value:"莿桐鄉"},
        {zipcode:648,label:"西螺鎮",value:"西螺鎮"},
        {zipcode:649,label:"二崙鄉",value:"二崙鄉"},
        {zipcode:651,label:"北港鎮",value:"北港鎮"},
        {zipcode:652,label:"水林鄉",value:"水林鄉"},
        {zipcode:653,label:"口湖鄉",value:"口湖鄉"},
        {zipcode:654,label:"四湖鄉",value:"四湖鄉"},
        {zipcode:655,label:"元長鄉",value:"元長鄉"},
                
    ],
    "嘉義市":[
        {zipcode:600,label:"東區",value:"東區"},
        {zipcode:600,label:"西區",value:"西區"},

    ],

    "嘉義縣":[
        {zipcode:602,label:"番路鄉",value:"番路鄉"},
        {zipcode:603,label:"梅山鄉",value:"梅山鄉"},
        {zipcode:604,label:"竹崎鄉",value:"竹崎鄉"},
        {zipcode:605,label:"阿里山鄉",value:"阿里山鄉"},
        {zipcode:606,label:"中埔鄉",value:"中埔鄉"},
        {zipcode:607,label:"大埔鄉",value:"大埔鄉"},
        {zipcode:608,label:"水上鄉",value:"水上鄉"},
        {zipcode:611,label:"鹿草鄉",value:"鹿草鄉"},
        {zipcode:612,label:"太保市",value:"太保市"},
        {zipcode:613,label:"朴子市",value:"朴子市"},
        {zipcode:614,label:"東石鄉",value:"東石鄉"},
        {zipcode:615,label:"六腳鄉",value:"六腳鄉"},
        {zipcode:616,label:"新港鄉",value:"新港鄉"},
        {zipcode:621,label:"民雄鄉",value:"民雄鄉"},
        {zipcode:622,label:"大林鎮",value:"大林鎮"},
        {zipcode:623,label:"溪口鄉",value:"溪口鄉"},
        {zipcode:624,label:"義竹鄉",value:"義竹鄉"},
        {zipcode:625,label:"布袋鎮",value:"布袋鎮"},
        
    ],
    "台南市":[
        {zipcode:700,label:"中西區",value:"中西區"},
        {zipcode:701,label:"東區",value:"東區"},
        {zipcode:702,label:"南區",value:"南區"},
        {zipcode:704,label:"北區",value:"北區"},
        {zipcode:708,label:"安平區",value:"安平區"},
        {zipcode:709,label:"安南區",value:"安南區"},
        {zipcode:710,label:"永康區",value:"永康區"},
        {zipcode:711,label:"歸仁區",value:"歸仁區"},
        {zipcode:712,label:"新化區",value:"新化區"},
        {zipcode:713,label:"左鎮區",value:"左鎮區"},
        {zipcode:714,label:"玉井區",value:"玉井區"},
        {zipcode:715,label:"楠西區",value:"楠西區"},
        {zipcode:716,label:"南化區",value:"南化區"},
        {zipcode:717,label:"仁德區",value:"仁德區"},
        {zipcode:718,label:"關廟區",value:"關廟區"},
        {zipcode:719,label:"龍崎區",value:"龍崎區"},
        {zipcode:720,label:"官田區",value:"官田區"},
        {zipcode:721,label:"麻豆區",value:"麻豆區"},
        {zipcode:722,label:"佳里區",value:"佳里區"},
        {zipcode:723,label:"西港區",value:"西港區"},
        {zipcode:724,label:"七股區",value:"七股區"},
        {zipcode:725,label:"將軍區",value:"將軍區"},
        {zipcode:726,label:"學甲區",value:"學甲區"},
        {zipcode:727,label:"北門區",value:"北門區"},
        {zipcode:730,label:"新營區",value:"新營區"},
        {zipcode:731,label:"後壁區",value:"後壁區"},
        {zipcode:732,label:"白河區",value:"白河區"},
        {zipcode:733,label:"東山區",value:"東山區"},
        {zipcode:734,label:"六甲區",value:"六甲區"},
        {zipcode:735,label:"下營區",value:"下營區"},
        {zipcode:736,label:"柳營區",value:"柳營區"},
        {zipcode:737,label:"鹽水區",value:"鹽水區"},
        {zipcode:741,label:"善化區",value:"善化區"},
        {zipcode:742,label:"大內區",value:"大內區"},
        {zipcode:743,label:"山上區",value:"山上區"},
        {zipcode:744,label:"新市區",value:"新市區"},
        {zipcode:745,label:"安定區",value:"安定區"},
        
    ],

    "高雄市":[
        {zipcode:800,label:"新興區",value:"新興區"},
        {zipcode:801,label:"前金區",value:"前金區"},
        {zipcode:802,label:"苓雅區",value:"苓雅區"},
        {zipcode:803,label:"鹽埕區",value:"鹽埕區"},
        {zipcode:804,label:"鼓山區",value:"鼓山區"},
        {zipcode:805,label:"旗津區",value:"旗津區"},
        {zipcode:806,label:"前鎮區",value:"前鎮區"},
        {zipcode:807,label:"三民區",value:"三民區"},
        {zipcode:811,label:"楠梓區",value:"楠梓區"},
        {zipcode:812,label:"小港區",value:"小港區"},
        {zipcode:813,label:"左營區",value:"左營區"},
        {zipcode:814,label:"仁武區",value:"仁武區"},
        {zipcode:815,label:"大社區",value:"大社區"},
        {zipcode:820,label:"岡山區",value:"岡山區"},
        {zipcode:821,label:"路竹區",value:"路竹區"},
        {zipcode:822,label:"阿蓮區",value:"阿蓮區"},
        {zipcode:823,label:"田寮區",value:"田寮區"},
        {zipcode:824,label:"燕巢區",value:"燕巢區"},
        {zipcode:825,label:"橋頭區",value:"橋頭區"},
        {zipcode:826,label:"梓官區",value:"梓官區"},
        {zipcode:827,label:"彌陀區",value:"彌陀區"},
        {zipcode:828,label:"永安區",value:"永安區"},
        {zipcode:829,label:"湖內區",value:"湖內區"},
        {zipcode:830,label:"鳳山區",value:"鳳山區"},
        {zipcode:831,label:"大寮區",value:"大寮區"},
        {zipcode:832,label:"林園區",value:"林園區"},
        {zipcode:833,label:"鳥松區",value:"鳥松區"},
        {zipcode:840,label:"大樹區",value:"大樹區"},
        {zipcode:842,label:"旗山區",value:"旗山區"},
        {zipcode:843,label:"美濃區",value:"美濃區"},
        {zipcode:844,label:"六龜區",value:"六龜區"},
        {zipcode:845,label:"內門區",value:"內門區"},
        {zipcode:846,label:"杉林區",value:"杉林區"},
        {zipcode:847,label:"甲仙區",value:"甲仙區"},
        {zipcode:848,label:"桃源區",value:"桃源區"},
        {zipcode:849,label:"那瑪夏區",value:"那瑪夏區"},
        {zipcode:851,label:"茂林區",value:"茂林區"},
        {zipcode:852,label:"茄萣區",value:"茄萣區"},
                
    ],
    "屏東縣":[
        {zipcode:900,label:"屏東市",value:"屏東市"},
        {zipcode:901,label:"三地門鄉",value:"三地門鄉"},
        {zipcode:902,label:"霧台鄉",value:"霧台鄉"},
        {zipcode:903,label:"瑪家鄉",value:"瑪家鄉"},
        {zipcode:904,label:"九如鄉",value:"九如鄉"},
        {zipcode:905,label:"里港鄉",value:"里港鄉"},
        {zipcode:906,label:"高樹鄉",value:"高樹鄉"},
        {zipcode:907,label:"鹽埔鄉",value:"鹽埔鄉"},
        {zipcode:908,label:"長治鄉",value:"長治鄉"},
        {zipcode:909,label:"麟洛鄉",value:"麟洛鄉"},
        {zipcode:911,label:"竹田鄉",value:"竹田鄉"},
        {zipcode:912,label:"內埔鄉",value:"內埔鄉"},
        {zipcode:913,label:"萬丹鄉",value:"萬丹鄉"},
        {zipcode:920,label:"潮州鎮",value:"潮州鎮"},
        {zipcode:921,label:"泰武鄉",value:"泰武鄉"},
        {zipcode:922,label:"來義鄉",value:"來義鄉"},
        {zipcode:923,label:"萬巒鄉",value:"萬巒鄉"},
        {zipcode:924,label:"崁頂鄉",value:"崁頂鄉"},
        {zipcode:925,label:"新埤鄉",value:"新埤鄉"},
        {zipcode:926,label:"南州鄉",value:"南州鄉"},
        {zipcode:927,label:"林邊鄉",value:"林邊鄉"},
        {zipcode:928,label:"東港鎮",value:"東港鎮"},
        {zipcode:929,label:"琉球鄉",value:"琉球鄉"},
        {zipcode:931,label:"佳冬鄉",value:"佳冬鄉"},
        {zipcode:932,label:"新園鄉",value:"新園鄉"},
        {zipcode:940,label:"枋寮鄉",value:"枋寮鄉"},
        {zipcode:941,label:"枋山鄉",value:"枋山鄉"},
        {zipcode:942,label:"春日鄉",value:"春日鄉"},
        {zipcode:943,label:"獅子鄉",value:"獅子鄉"},
        {zipcode:944,label:"車城鄉",value:"車城鄉"},
        {zipcode:945,label:"牡丹鄉",value:"牡丹鄉"},
        {zipcode:946,label:"恆春鎮",value:"恆春鎮"},
        {zipcode:947,label:"滿州鄉",value:"滿州鄉"},
                
    ],
    "台東縣":[
        {zipcode:950,label:"台東市"  ,value:"台東市"  },
        {zipcode:951,label:"綠島鄉"  ,value:"綠島鄉"  },
        {zipcode:952,label:"蘭嶼鄉"  ,value:"蘭嶼鄉"  },
        {zipcode:953,label:"延平鄉"  ,value:"延平鄉"  },
        {zipcode:954,label:"卑南鄉"  ,value:"卑南鄉"  },
        {zipcode:955,label:"鹿野鄉"  ,value:"鹿野鄉"  },
        {zipcode:956,label:"關山鎮"  ,value:"關山鎮"  },
        {zipcode:957,label:"海端鄉"  ,value:"海端鄉"  },
        {zipcode:958,label:"池上鄉"  ,value:"池上鄉"  },
        {zipcode:959,label:"東河鄉"  ,value:"東河鄉"  },
        {zipcode:961,label:"成功鎮"  ,value:"成功鎮"  },
        {zipcode:962,label:"長濱鄉"  ,value:"長濱鄉"  },
        {zipcode:963,label:"太麻里鄉" ,value:"太麻里鄉" },
        {zipcode:964,label:"金峰鄉"  ,value:"金峰鄉"  },
        {zipcode:965,label:"大武鄉"  ,value:"大武鄉"  },
        {zipcode:966,label:"達仁鄉"  ,value:"達仁鄉"  },
                
    ],
    "花蓮縣":[
        {zipcode:970,label:"花蓮市"  ,value:"花蓮市"  },
        {zipcode:971,label:"新城鄉"  ,value:"新城鄉"  },
        {zipcode:972,label:"秀林鄉"  ,value:"秀林鄉"  },
        {zipcode:973,label:"吉安鄉"  ,value:"吉安鄉"  },
        {zipcode:974,label:"壽豐鄉"  ,value:"壽豐鄉"  },
        {zipcode:975,label:"鳳林鎮"  ,value:"鳳林鎮"  },
        {zipcode:976,label:"光復鄉"  ,value:"光復鄉"  },
        {zipcode:977,label:"豐濱鄉"  ,value:"豐濱鄉"  },
        {zipcode:978,label:"瑞穗鄉"  ,value:"瑞穗鄉"  },
        {zipcode:979,label:"萬榮鄉"  ,value:"萬榮鄉"  },
        {zipcode:981,label:"玉里鎮"  ,value:"玉里鎮"  },
        {zipcode:982,label:"卓溪鄉"  ,value:"卓溪鄉"  },
        {zipcode:983,label:"富里鄉"  ,value:"富里鄉"  },
                
    ],
    "宜蘭縣":[
        {zipcode:260,label:"宜蘭市"  ,value:"宜蘭市"  },
        {zipcode:261,label:"頭城鎮"  ,value:"頭城鎮"  },
        {zipcode:262,label:"礁溪鄉"  ,value:"礁溪鄉"  },
        {zipcode:263,label:"壯圍鄉"  ,value:"壯圍鄉"  },
        {zipcode:264,label:"員山鄉"  ,value:"員山鄉"  },
        {zipcode:265,label:"羅東鎮"  ,value:"羅東鎮"  },
        {zipcode:266,label:"三星鄉"  ,value:"三星鄉"  },
        {zipcode:267,label:"大同鄉"  ,value:"大同鄉"  },
        {zipcode:268,label:"五結鄉"  ,value:"五結鄉"  },
        {zipcode:269,label:"冬山鄉"  ,value:"冬山鄉"  },
        {zipcode:270,label:"蘇澳鎮"  ,value:"蘇澳鎮"  },
        {zipcode:272,label:"南澳鄉"  ,value:"南澳鄉"  },
        {zipcode:290,label:"釣魚臺"  ,value:"釣魚臺"  },

    ],
    "澎湖縣":[
        {zipcode:880,label:"馬公市"  ,value:"馬公市"  },
        {zipcode:881,label:"西嶼鄉"  ,value:"西嶼鄉"  },
        {zipcode:882,label:"望安鄉"  ,value:"望安鄉"  },
        {zipcode:883,label:"七美鄉"  ,value:"七美鄉"  },
        {zipcode:884,label:"白沙鄉"  ,value:"白沙鄉"  },
        {zipcode:885,label:"湖西鄉"  ,value:"湖西鄉"  },
                
    ],
    "金門縣":[
        {zipcode:890,label:"金沙鎮"  ,value:"金沙鎮"  },
        {zipcode:891,label:"金湖鎮"  ,value:"金湖鎮"  },
        {zipcode:892,label:"金寧鄉"  ,value:"金寧鄉"  },
        {zipcode:893,label:"金城鎮"  ,value:"金城鎮"  },
        {zipcode:894,label:"烈嶼鄉"  ,value:"烈嶼鄉"  },
        {zipcode:896,label:"烏坵鄉"  ,value:"烏坵鄉"  },
                
    ],
    "連江縣":[
        {zipcode:209,label:"南竿鄉"  ,value:"南竿鄉"  },
        {zipcode:210,label:"北竿鄉"  ,value:"北竿鄉"  },
        {zipcode:211,label:"莒光鄉"  ,value:"莒光鄉"  },
        {zipcode:212,label:"東引鄉"  ,value:"東引鄉"  },
                
    ],
    
};

// B2C遊戲幣出售區交易狀態 (int)
const B2C_ORDERSTATUS = {
    NOTPROCESSED: 0,
    SUCCESS: 1,
    FAIL: -1,
    PROCESSED: 2,
};

// B2C遊戲幣出售區交易狀態 (string)
const B2C_ORDERSTATUS_STRING = {
    [B2C_ORDERSTATUS.NOTPROCESSED]: '尚未處理',
    [B2C_ORDERSTATUS.SUCCESS]: '交易完成',
    [B2C_ORDERSTATUS.FAIL]: '交易取消',
    [B2C_ORDERSTATUS.PROCESSED]: '處理中',
};

// C2C收購區交易狀態 (int)
const C2C_PURCHASE_ORDERSTATUS = {
    NOTSALE: 1,
    TOBUY: 85,
    BUYORDER: 3,
    CANCEL_BUYER: 55,
    CANCEL_SALER: 33,
    CANCEL_9199: 143,
    WAITFORBUYER: 5,
    DISPUTEDORDER: 10,
    ORDERFINISH_9199: 91,
    ORDERFINISH: 35,
    ORDERREVENUE: 14,
};

// C2C收購區交易狀態 (string)
const C2C_PURCHASE_ORDERSTATUS_STRING = {
    [C2C_PURCHASE_ORDERSTATUS.NOTSALE]: '待回覆(待出售)',
    [C2C_PURCHASE_ORDERSTATUS.TOBUY]: '待收購',
    [C2C_PURCHASE_ORDERSTATUS.BUYORDER]: '移交中',
    [C2C_PURCHASE_ORDERSTATUS.CANCEL_BUYER]: '買方取消',
    [C2C_PURCHASE_ORDERSTATUS.CANCEL_SALER]: '賣方取消',
    [C2C_PURCHASE_ORDERSTATUS.CANCEL_9199]: '9199取消',
    [C2C_PURCHASE_ORDERSTATUS.WAITFORBUYER]: '領收中',
    [C2C_PURCHASE_ORDERSTATUS.DISPUTEDORDER]: '爭議中',
    [C2C_PURCHASE_ORDERSTATUS.ORDERFINISH_9199]: '9199交易完成',
    [C2C_PURCHASE_ORDERSTATUS.ORDERFINISH]: '交易完成',
    [C2C_PURCHASE_ORDERSTATUS.ORDERREVENUE]: '交易完成已入帳',
};

// 會員發票載具類型
const CARRIER_TYPE = {
    NONE: 0,
    MEMBER: 1,
    COMMONS: 2,
};

// 點卡購物車狀態 (int)
const CARTORDER_STATUS = {
    UNPAY: 0,
    SUCCESS: 1,
    FAIL: 2,
    PARTFAIL: 3,
    PROCESSED: 4,
    EXPIREDPAY: 5,
    INVENTORYSHORTAGE: 6,
};

// 點卡購物車狀態 (string)
const CARTORDER_STATUS_STRING = {
    [CARTORDER_STATUS.UNPAY]: '等待付款',
    [CARTORDER_STATUS.SUCCESS]: '交易成功',
    [CARTORDER_STATUS.FAIL]: '訂單建立失敗',
    [CARTORDER_STATUS.PARTFAIL]: '部分訂單建立失敗',
    [CARTORDER_STATUS.PROCESSED]: '購物車已處理過(付款狀態微已付款或訂單建立完成或部分訂單已建立)',
    [CARTORDER_STATUS.EXPIREDPAY]: `付款時間已於購物車有效時間(${PAYMENT_LIMIT_HOUR_TEXT});`,
    [CARTORDER_STATUS.INVENTORYSHORTAGE]: '部分商品庫存不足，訂單尚未建立',
};

// 產品 class id
const PRODUCT_CLASS_ID = {
    POINTCARD_PRODUCT: 1,
    VITEM_PRODUCT: 2,
    FREE_PRODUCT: 3,
    BONUS_PRODUCT: 4,
};

/*--------------------------
    Method
--------------------------*/

function initFB(scope) {
    const p = new Promise((resolve, reject) => {
        if (!window.FB) {
            if (!document.getElementById('fb-root')) {
            var elem = document.createElement('DIV');
            elem.id = 'fb-root';
            document
                .body
                .appendChild(elem);
            }

            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: fbconfig.appId,
                    cookie: true,
                    status: true,
                    xfbml: true,
                    frictionlessRequests: true,
                    version: fbconfig.version
                });
                resolve();
            };

            (function (d, s, id, APPID) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.async = true;
                js.src = '//connect.facebook.net/zh_TW/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk', fbconfig.appId);
        } else {
            resolve();
        }
    });

    return p;
}

// ------------------------------
// 檢查是否為 url 格式
// ------------------------------
function isUrl(url) {

    // eslint-disable-next-line
    return /^([a-z]([a-z]|\d|\+|-|\.)*):(\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?((\[(|(v[\da-f]{1,}\.(([a-z]|\d|-|\.|_|~)|[!\$&'\(\)\*\+,;=]|:)+))\])|((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=])*)(:\d*)?)(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*|(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)|((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)|((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)){0})(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
}

// ------------------------------
// 檢查是否為 email 格式
// ------------------------------
function isEmail(email) {

    // eslint-disable-next-line
    const isEmail = /^[^@^\s]+@[^\.@^\s]+(\.[^\.@^\s]+)+$/.test(email);

    // 基本格式錯誤
    if (!isEmail) {
        return false;
    }

    const _email = email.toLowerCase();
    const sub = _email.split('@')[1].toLowerCase();
    const [host, commercial, ...others] = sub.split('.');

    const GMAIL = 'gmail';
    const YAHOO = 'yahoo';
    const ICLOUD = 'icloud';
    const COM = 'com';

    // 對應各項格式 ( 只有yahoo在.com後面還會有字串 )
    if (
        (host === GMAIL && commercial === COM && others.length === 0)
        || (host === YAHOO && commercial === COM)
        || (host === ICLOUD && commercial === COM && others.length === 0)
    ) {
        return true;
    }

    const checkTemplateString = (template, withoutOthers) => {

        const { length: hostLen } = host;
        const { length: templateLen } = template;

        // email與template的長度超過+-1，不相似
        if (hostLen !== templateLen && hostLen !== templateLen - 1 && hostLen !== templateLen + 1) {
            return true;
        }

        const equalCount = [...template].reduce((acc, cur) => {

            if (host.includes(cur)) {
                acc += 1;
            }

            return acc;
        }, 0);

        // 字串拆解後，match的字串數量為驗證mail的+-1
        const probablyMatch = equalCount === hostLen || equalCount === hostLen + 1 || equalCount === hostLen - 1;

        if (probablyMatch && (commercial !== COM || (withoutOthers && others.length > 0))) {
            return false;
        }

        return !probablyMatch;
    };

    // 與template相似度高，但不通過驗證
    if (!checkTemplateString(GMAIL, true) || !checkTemplateString(YAHOO) || !checkTemplateString(ICLOUD, true)) {
        return false;
    }

    return true;
}

// ------------------------------
// 檢查是否為 Youtube 格式
// ------------------------------
function isYoutube(url) {

    // eslint-disable-next-line
    return /^(?:https?:\/\/)?(?:www\.|m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(url);
}

// ------------------------------
// 將沒有http://的url加上http://
// ------------------------------
function addhttp(url) {

    // eslint-disable-next-line
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = 'http://' + url;
    }
    return url;
}

// ------------------------------
// 取得網址Domain
// ------------------------------
function getUrlDomain(sourceurl) {

    return url.parse(sourceurl).hostname;
}

// ------------------------------
// 取得元素在畫面上的絕對位置
// ------------------------------
function cumulativeOffset(element) {

    if (!element) {
        return undefined;
    }

    var top = 0, left = 0;

    do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while (element);

    return {top: top, left: left};
}

// ------------------------------
// 移除一些看起來長得一樣但是卻有一些奇怪字元的方法
// ------------------------------
function replaceNoWidthSpace(text) {

    if (text) {
        return text.replace(/[\u200B-\u200D\uFEFF]/g, '');
    } else {
        return text;
    }
}

// ------------------------------
// 檢查是否為 空値 空陣列 空obj
// ------------------------------
function isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) {
        return true;
    }
  
    // Assume if it has a length property with a non-zero value that that property
    // is correct.
    if (obj.length > 0) {
        return false;
    }

    if (obj.length === 0) {
        return true;
    }
  
    // If it isn't an object at this point it is empty, but it can't be anything
    // *but* empty Is it empty?  Depends on your application.
    if (typeof obj !== "object") {
        return true;
    }
  
    // Otherwise, does it have any properties of its own? Note that this doesn't
    // handle toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) {
            return false;
        }
    }
  
  return true;
}

function getMemberAvatarRsc({ sex, isvip, isemailauth } = {}){

    let rsc = 'about:blank';

    if (sex === 0){
        if (isvip)
        rsc = 'images/member_avatar/vip_member_female_avatar.svg'; // 女性vip會員
        else if (isemailauth)
        rsc = 'images/member_avatar/full_member_female_avatar.svg'; // 女性正式會員(mail已認證)
        else 
        rsc = 'images/member_avatar/general_member_female_avatar.svg'; // 女性一般會員(mail未認證)
    } else if (sex === 1){
        if (isvip)
        rsc = 'images/member_avatar/vip_member_male_avatar.svg'; // 男性vip會員
        else if (isemailauth)
        rsc = 'images/member_avatar/full_member_male_avatar.svg'; // 男性正式會員(mail已認證)
        else
        rsc = 'images/member_avatar/general_member_male_avatar.svg'; // 男性一般會員(mail未認證)
    }

    return rsc;
}

function chkMemberID(str){
    return /^[a-zA-Z0-9]{4,16}$/.test(str);   
}

function chkPassword(str){
    return /^[a-zA-Z0-9]{6,10}$/.test(str);   
    
}

function chkMobile(str) {
    return /^09(\d){8}$/.test(str);
}

/**
 * 檢查字串是否都是中文
 * @param {string} str
 * @return {boolean}
 * @author Ac
 */
function isChinese(str = ''){

    if (typeof str !== 'string') {
        return false;
    }

    return /^[\u4e00-\u9fa5]+$/.test(str);
}

/**
 * 檢查字串是否含有特殊符號 ( 這邊指英數中以外的都算是特殊符號 )
 * @param {string} str
 * @return {boolean}
 * @author Ac
 */
function checkStringHaveSymbol(str = '') {

    if (!str || typeof str !== 'string') {
        return false;
    }

    return !/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(str);
}

/**
 * 檢查字串長度
 * @param {number} start 
 * @param {number} end 
 * @return {boolean}
 * @author Ac
 */
function checkStringLength(str = '', start = 0, end = 0) {

    if (typeof str !== 'string') {
        return false;
    }

    if (
        !Number.isFinite(start)
        || !Number.isFinite(end)
        || start < 0
        || end < 0
        || start >= end
        || end <= start
    ) {
        return false;
    }

    const len = str.length;

    if (len < start || len > end) {
        return false;
    }

    return true;
}

// check 身分證字號
function chkIdnumber(idnumber){
    if (isEmpty(idnumber)) {
        return {result : false,message : "請填寫身分證字號！"};
    }
    if(!isTwIDCard(idnumber)){
        return {result : false,message : "身分證字號格式不正確！"};
    }	
    else{
        return {result : true};
        
    }
} 

// 檢查台灣身分證字號
function isTwIDCard(fStr)	{
    if(fStr.length !== 10) return false; 

    var idArr = []; // new Array();
    idArr['A']=10;  idArr['B']=11;
    idArr['C']=12;  idArr['D']=13;
    idArr['E']=14;  idArr['F']=15;
    idArr['G']=16;  idArr['H']=17;
    idArr['J']=18;  idArr['K']=19;
    idArr['L']=20;  idArr['M']=21;
    idArr['N']=22;  idArr['P']=23;
    idArr['Q']=24;  idArr['R']=25;
    idArr['S']=26;  idArr['T']=27;
    idArr['U']=28;  idArr['V']=29;
    idArr['X']=30;  idArr['Y']=31;
    idArr['W']=32;  idArr['Z']=33;
    idArr['I']=34;  idArr['O']=35; 
    var N = []; // new Array();
    for (var i=1;i<=10;i++) { 
        N[i] = fStr.substr(i-1,1); 
        if(i > 1){
        N[i] = Number(N[i]);
        }
    } 	
    N[1] = N[1].toUpperCase( );
    N[1] = idArr[N[1]]; 
            
    if(N[1] < 10 || N[1]>35) return false;
    if(N[2]<1 || N[2]>2) return false;
            
    N[1] = N[1].toString(); 
            
    var N1  = Number(N[1].substr(0,1)); 
    var N12 = Number(N[1].substr(1,1)); 
    var express = (N1+N12*9+N[2]*8+N[3]*7+N[4]*6+N[5]*5+N[6]*4+N[7]*3+N[8]*2+N[9]*1+N[10]) % 10; 
    if (express !== 0) 
        return false; 

    return true; 
}

// 取得1929年到距今18年前的年份
function getBrithdayYear() {
    const now = new Date().getFullYear();
    const range = (now - 18) - 1929;
    const list = [];

    for (let index = 0; index < range + 1; index++) {
        list.push(index + 1929);
    }

    return list;
};

// 檢查是否滿18歲
function hasOver18(timestamp){

    const _date = new Date();
    const _yy = _date.getFullYear() - 18;
    const _mm = (_date.getMonth() + 1) < 10 ? `0${_date.getMonth() + 1}` : _date.getMonth() + 1;
    const _dd = _date.getDate() < 10 ? `0${_date.getDate()}` : _date.getDate();

    const date = new Date(timestamp);
    const yy = date.getFullYear();
    const mm = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const dd = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

    const _dateStr = +`${_yy}${_mm}${_dd}`;
    const dateStr = +`${yy}${mm}${dd}`;
    
    return _dateStr >= dateStr;
}

function parseAddress(address){
    if(!address)return address;
    
    var address_split = address.split(" ");
    var len = address_split.length;
    var street = "";
    for(var i = 3; i < len; i++){
        street += address_split[i];
        
    }
    return {zipcode : address_split[0],city : address_split[1],region :address_split[2],address : street};
}

/* =====================
 * 檢查是否為手機載具條碼
 * ===================== */
function isMobilePhoneCarrierID(mobilePhoneCarrierID) {
    return (/^\/[0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ+\-.]{7}$/.test(mobilePhoneCarrierID));
}

/* =====================
* 檢查是否為自然人憑證條碼
* ===================== */
function isDigitalCitizenCarrierID(digitalCitizenCarrierID) {
    return (/^[ABCDEFGHIJKLMNOPQRSTUVWXYZ]{2}[0123456789]{14}$/.test(digitalCitizenCarrierID));
}

/* =====================
* 取得字串長度 ( ASCII = 1, 非ASCII = 2 )
* ===================== */
function getStringLength(str = '') {
    const _str = str.trim();
    return _str.replace(/[^\x00-\xff]/ig, '**').length;
}

/* =====================
* 取得虛寶市集幣商是否營業
* ===================== */
function getVProviderIsOpen ({ buydayofweek, buytime_limit } = {}) {

    // check params is number
    if(buydayofweek !== +buydayofweek || buytime_limit !== +buytime_limit) {
        return false;
    }

    let isWeekOpen = false;

    if(buydayofweek > 0) {

        const result = {
            0: false,
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
        };

        let _buydayofweek = buydayofweek;

        for(let i = 6; i >= 0; i--) {
            const int = Math.pow(2, i);

            if(int <= _buydayofweek) {
                result[i] = true;
                _buydayofweek -= int;
            }
        }

        const nowDay = new Date().getDay();

        if(result[nowDay]) {
            isWeekOpen = true;
        }
    } else if (buydayofweek === 0) {
        isWeekOpen = true;
    }

    let isOpen = false;

    if (isWeekOpen && buytime_limit > 0) {
        // buytime_limit 營業起始時間不會補0，營業結束時間會補0
        const buytime_s = Math.floor(buytime_limit / 100);
        let buytime_e = buytime_limit % 100;
        let nowHour = new Date().getHours();

        if (buytime_e < buytime_s) {

            buytime_e += 24;

            if (nowHour < buytime_s) {
                nowHour += 24;
            };
        }

        if ((buytime_s <= nowHour) && (nowHour <= buytime_e)) {
            isOpen = true;
        }
    } else if(isWeekOpen && buytime_limit === -1){
        isOpen = true;
    }

    return isOpen;
}

// 取得xxxx年至今年的數字
function getYear(year, ascend) {

    const _year = new Date().getFullYear();

    const result = [];

    for (let i = _year; i > year; i--) {
        result.push(`${i}`);
    }


    return ascend ? result.reverse() : result;
}

/**
 * format已經捐贈的發票號碼
 * @param {string} string
 * @author Ac
 * @time 2020 / 03 / 31
 */
function formatDonatedInvoiceNumber(string = '') {

    let _string = string;
    if (typeof _string !== 'string') {
        _string += '';
    }


    const stringArr = [..._string];
    return stringArr.slice(0, stringArr.length - 3).join('') + '***';
}

/**
|--------------------------------------------------
| 取得產品的 router name
|--------------------------------------------------
*/
function getProductRouterName(product) {

    if(!product || !product.productclassid) return;

    let stateName = '';

    switch (product.productclassid) {
        case PRODUCT_CLASS_ID.POINTCARD_PRODUCT:
            stateName = CupoyRouter.STATES.POINTCARD_PRODUCT;
            break;
        case PRODUCT_CLASS_ID.VITEM_PRODUCT:
            stateName = CupoyRouter.STATES.VITEM_PRODUCT;
            break;
        case PRODUCT_CLASS_ID.FREE_PRODUCT:
            stateName = CupoyRouter.STATES.FREE_PRODUCT;
            break;
        case PRODUCT_CLASS_ID.BONUS_PRODUCT:
            stateName = CupoyRouter.STATES.BONUS_PRODUCT;
            break;
        default:
            break;
    }

    return stateName;
}

/**
 * 取得隨機整數
 * @param {number} min 最小隨機數
 * @param {number} max 最大隨機數
 * @author Ac
 * @time 2020 / 08 / 10
 */
function getRandomInt(min = 0 , max = 1) {       

    const byteArray = new Uint8Array(1);
    window.crypto.getRandomValues(byteArray);

    const range = max - min + 1;
    const max_range = 256;

    if (byteArray[0] >= Math.floor(max_range / range) * range)
        return getRandomInt(min, max);
    return min + (byteArray[0] % range);
}

/**
 * 取得萊爾富繳費單號
 * 
 * @param {number} cartid 
 */
function getHILIFEPaymentId(cartid) {
    const _cartid = '' + cartid;
    const paymentId = '919' + _cartid.slice(-9);
    return paymentId;
}

// eslint-disable-next-line
export default {
    /*--------------------------
        Variables
    --------------------------*/
    domain,
    isRelease,
    grecaptcha_key,
    allPayCheckOutURL,
    imgSrcRootURL,
    PAYMENT_LIMIT_HOUR,
    PAYMENT_LIMIT_HOUR_TEXT,
    PAYTYPE, // 付款方式 (int)
    PAYTYPE_STRING, // 付款方式 (string)
    CASESTATUS, // 客訴處理狀態 (int)
    CASESTATUS_STRING, // 退款狀態 (string)
    REFUND_FLAG, // 退款狀態 (int)
    REFUND_FLAG_STRING, // 退款狀態 (string)
    BONUS_OPTYPES, // 發財金使用種類 (int)
    BONUS_OPTYPES_STRING, // 發財金使用種類 (string)
    CITIES, // 台灣縣市列表
    REGIONS, // 台灣鄉鎮區列表  
    B2C_ORDERSTATUS, // B2C遊戲幣出售區交易狀態 (int)
    B2C_ORDERSTATUS_STRING, // B2C遊戲幣出售區交易狀態 (string)
    C2C_PURCHASE_ORDERSTATUS, // C2C收購區交易狀態 (int)
    C2C_PURCHASE_ORDERSTATUS_STRING, // C2C收購區交易狀態 (string)
    CARRIER_TYPE, // 會員發票載具類型
    CARTORDER_STATUS, // 點卡購物車狀態 (int)
    CARTORDER_STATUS_STRING, // 點卡購物車狀態 (string)
    PRODUCT_CLASS_ID, // 產品 class id
    /*--------------------------
        Method
    --------------------------*/
    isUrl, // 檢查是否為 url 格式
    isEmail, // 檢查是否為 email 格式
    isYoutube, // 檢查是否為 Youtube 格式
    addhttp, // 將沒有http://的url加上http://
    getUrlDomain, // 取得網址Domain
    cumulativeOffset, // 取得元素在畫面上的絕對位置
    replaceNoWidthSpace, // 移除一些看起來長得一樣但是卻有一些奇怪字元的方法
    isEmpty, // 檢查是否為 空値 空陣列 空obj
    getMemberAvatarRsc, // 取得會員大頭貼
    // FB Config for FB Login...etc
    fbconfig,
    initFB,
    chkMemberID, // check 會員帳號規則
    chkPassword, // check 密碼規則
    chkMobile, // check 台灣手機號碼
    isChinese, // check 是否為中文字
    checkStringHaveSymbol, // 檢查字串是否含有特殊符號 ( 這邊指英數中以外的都算是特殊符號 )
    checkStringLength, // 檢查字串長度
    chkIdnumber, // 檢查身份證字號輸入規則
    getBrithdayYear, // 取得1929年到距今18年前的年份
    hasOver18, // 檢查是否滿18歲
    parseAddress,
    isMobilePhoneCarrierID, // 檢查是否為手機載具條碼
    isDigitalCitizenCarrierID, // 檢查是否為自然人憑證條碼
    getProductRouterName, // 取得產品的 router name
    getStringLength, // 取得字串長度 ( ASCII = 1, 非ASCII = 2 )
    getVProviderIsOpen, // 取得虛寶市集幣商是否營業
    getYear, // 取得xxxx年至今年的數字
    formatDonatedInvoiceNumber, // format已經捐贈的發票號碼
    getRandomInt, // 取得隨機整數
    getHILIFEPaymentId, // 取得萊爾富繳費單號
};
